exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaigns-ravas-tsx": () => import("./../../../src/pages/campaigns/ravas.tsx" /* webpackChunkName: "component---src-pages-campaigns-ravas-tsx" */),
  "component---src-pages-challenge-crush-challenge-token-tsx": () => import("./../../../src/pages/challengeCrush/[challengeToken].tsx" /* webpackChunkName: "component---src-pages-challenge-crush-challenge-token-tsx" */),
  "component---src-pages-delete-account-index-tsx": () => import("./../../../src/pages/deleteAccount/index.tsx" /* webpackChunkName: "component---src-pages-delete-account-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legal-code-of-conduct-tsx": () => import("./../../../src/pages/legal/codeOfConduct.tsx" /* webpackChunkName: "component---src-pages-legal-code-of-conduct-tsx" */),
  "component---src-pages-legal-privacy-policy-tsx": () => import("./../../../src/pages/legal/privacyPolicy.tsx" /* webpackChunkName: "component---src-pages-legal-privacy-policy-tsx" */),
  "component---src-pages-legal-terms-conditions-tsx": () => import("./../../../src/pages/legal/termsConditions.tsx" /* webpackChunkName: "component---src-pages-legal-terms-conditions-tsx" */),
  "component---src-pages-link-index-tsx": () => import("./../../../src/pages/link/index.tsx" /* webpackChunkName: "component---src-pages-link-index-tsx" */),
  "component---src-pages-link-share-link-tsx": () => import("./../../../src/pages/link/[shareLink].tsx" /* webpackChunkName: "component---src-pages-link-share-link-tsx" */),
  "component---src-pages-post-share-index-tsx": () => import("./../../../src/pages/postShare/index.tsx" /* webpackChunkName: "component---src-pages-post-share-index-tsx" */),
  "component---src-pages-post-share-post-link-tsx": () => import("./../../../src/pages/postShare/[postLink].tsx" /* webpackChunkName: "component---src-pages-post-share-post-link-tsx" */)
}

